import _ from 'lodash'

const type = 'date';
const DateFieldImpl = (specification) => {

  const spec = _.cloneDeep(specification);

  function parse(stringValue) {
    let output = null;
    let messages = [];

    // see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date#date_time_string_format
    // for how this will parse the string input
    let parsedDate = Date.parse(stringValue);

    if (_.isNaN(parsedDate)) {
      messages.push("Value must be a valid date");
    } else {
      let date = new Date(parsedDate);
      output = {
        type: type,
        year: date.getFullYear(),
        month: date.getMonth() + 1,
        day: date.getDate()
      }
    }
  
    return {
      data: output,
      messages: messages,
    };
  };

  function parseFromPicker(stringValue) {

    // this is the very specific format that is produced by the UI date picker
    var DATE_INPUT_REGEX = /^(\d{4})-(\d{2})-(\d{2})$/;
    var match = stringValue?.match(DATE_INPUT_REGEX);

    if (Array.isArray(match)) {
      return {
        type: type,
        year: Number.parseInt(match[1]),
        month: Number.parseInt(match[2]),
        day: Number.parseInt(match[3])
      }
    }
    return null;
  };

  return {
    type,
    reference: spec.reference,
    parse: parse,
    parseFromPicker: parseFromPicker,
  }
}

export const DateField = {
  type,
  create: (specification) => DateFieldImpl(specification),
}
