<template>
  <div class="time-field control-group" v-if="isRow">
    <div class="controls" :title="label" v-show="isTableCellVisible">
      <input type="time" v-model="time" step="60" :disabled="isReadOnly" v-bind:class="[isValid ? '' : 'field-error', 'inspection-table-field', {dirty: isDirty}]" v-on:keydown.enter.prevent="">
    </div>
    <div class="controls" :title="label" v-show="!isTableCellVisible">
      &nbsp;
    </div>
  </div>
  <div class="time-field control-group" v-show="isVisible" v-else>
    <label v-bind:for="formId" class="control-label">{{ label }}<span v-if="isRequired">*</span></label>
    <div class="controls">
      <p class="help-block" v-if="hasHelpText">{{ helpText }}</p>
      <p class="criteria-block" v-if="showCriteria || showMandatoryText">
        <span v-if="showCriteria">{{ criteriaText }}</span>
        <span v-if="showMandatoryText">This field is mandatory.</span>
      </p>
      <input type="time" v-model="time" step="60" :disabled="isReadOnly" v-on:keydown.enter.prevent="" :class="[{dirty: isDirty}]">
    </div>
  </div>
</template>

<script>
  import _ from 'lodash'
  import fieldMixin from '../../mixins/field_mixin'
  import fieldLabelMixin from '../../mixins/field_label_mixin'
  import fieldVisibilityMixin from '../../mixins/field_visibility_mixin'
  import fieldValidationMixin from '../../mixins/field_validation_mixin'
  import { EventBus } from '../../lib/event_bus'
  import moment from 'moment'
  import { TimeField } from '../../lib/fields/_fields.js'

  export default {
    mixins: [fieldMixin, fieldLabelMixin, fieldVisibilityMixin, fieldValidationMixin],
    data: function () {
      let initialTime = this.timeFromData(this.data);

      return {
        initialTime: initialTime,
        time: initialTime
      }
    },
    watch: {
      data: function (data) {
        let newTime = this.timeFromData(data);
        if (!_.isEqual(this.time, newTime)) {
          this.time = newTime;
        }
      },
      time: function (newValue) {
        let dataValue = TimeField.create(this.specification).parse(newValue).data;
        this.currentValidity = this.assessValidity();
        this.$emit('update:data', dataValue)
      }
    },
    computed: {
      isPresent: function () {
        return !_.isEmpty(this.time)
      },      
      formattedTime: function () {
        if (_.isNil(this.data)) {
          return "";
        } else {
          return moment().hour(this.data.hour).minute(this.data.minute).second(this.data.second).format("h:mm A")
        }
      },
      isDirty: function () {
        return !(_.isEqual(this.initialTime, this.time));
      }
    },
    methods: {
      timeFromData: function(data) {
        return data 
          ? _.join([
              _.padStart(_.toSafeInteger(data.hour), 2, '0'),
              _.padStart(_.toSafeInteger(data.minute), 2, '0')
            ], ':')
          : '';
      },
    },
    created: function () {
      let vm = this;
      if (!!vm.underRollup){
        EventBus.$on(vm.underRollup, (eventData) => {
          vm.visibleUnderRollup = eventData['rollup'];
          let thisShowHide = eventData['groupShowHide'];
          if (!!thisShowHide) {
            vm.groupShowHide = thisShowHide;
            vm.assessValidity();
          }
        });
      };
      if (!!vm.controlledField){
        EventBus.$on(vm.controlEvent, (ShowHideData) => {
          let thisShowHide = ShowHideData[vm.reference];
          if (!!thisShowHide) {
            vm.controlShowHide = thisShowHide;
            vm.assessValidity();
          }
        });
      };
    }
  }
</script>

<style scoped lang="scss">
  .inspection-table-field {
    width: 95%;
    padding-right: 0px;
    margin-bottom: 0px;
  }
  .control-group {
    margin-bottom: 0px;  
  }
</style>